import { batch } from '@preact/signals-react';
import { posthog } from 'posthog-js';

import { authError, isAuthenticated, isLoading } from '../state/auth';
import {
    email,
    firstName,
    id,
    lastName,
    profilePictureUrl,
    workosId,
} from '../state/user';
import { AuthenticatedUser } from '../Types/User';

interface AuthResponse {
    authorizationUrl: string;
}

const updateUserState = (userData: AuthenticatedUser) => {
    batch(() => {
        firstName.value = userData.firstName || '';
        lastName.value = userData.lastName || '';
        workosId.value = userData.workosId || '';
        id.value = userData.id || -1;
        email.value = userData.email || '';
        profilePictureUrl.value = userData.profilePictureUrl || '';
    });
};

const clearUserState = (): void => {
    batch(() => {
        firstName.value = '';
        lastName.value = '';
        workosId.value = '';
        id.value = -1;
        email.value = '';
        profilePictureUrl.value = '';
    });
};

export const fetchUserData = async (): Promise<void> => {
    try {
        // attempt to get a user profile from a session ID, this checks if they have a valid login with workOS
        const response = await fetch('/user');
        if (!response.ok) {
            throw new Error('Failed to fetch user data');
        }
        const userData: AuthenticatedUser = await response.json();
        const alabEmails = ["assurancelab.cpa", "assurancelab.com.au", "pillargrc.com"];
        if (userData.redirectUrl && (userData.role == 'member' ||
                                     !alabEmails.includes(userData.email.split("@")[1]))) {
            window.location.href = userData.redirectUrl;
            return;
        }

        updateUserState(userData);
        isAuthenticated.value = true;

        posthog.identify(userData.email, {
            email: userData.email,
            name: `${userData.firstName} ${userData.lastName}`,
            id: userData.workosId,
            pillarId: userData.id,
        });
    } catch (error) {
        // Clear user data in signals
        clearUserState();
        isAuthenticated.value = false;

        if (error instanceof Error) {
            console.error('Error fetching user data:', error);
            authError.value = error?.message;
        } else {
            console.error('An unexpected error occurred:', error);
            authError.value = 'An unexpected error occurred during authentication.';
        }

        posthog.reset();

        // to-do: don't automate this, take them to a login screen with a button or it can just loop
        startAuthFlow();
    }
};

const startAuthFlow = async (): Promise<void> => {
    isLoading.value = true;
    authError.value = null;
    try {
        const response = await fetch('/auth');
        if (!response.ok) {
            throw new Error('Failed to fetch authorization URL');
        }
        const data: AuthResponse = await response.json();
        isAuthenticated.value = true;
        isLoading.value = false;
        window.location.href = data.authorizationUrl;
    } catch (error: unknown) {
        isAuthenticated.value = false;
        isLoading.value = false;
        if (error instanceof Error) {
            console.error(error.message);
            authError.value = error.message;
        } else {
            // Handle cases where the error might not be an Error object
            console.error('An unexpected error occurred:', error);
            authError.value = 'An unexpected error occurred';
        }
    }
};

export const signOutUser = async (): Promise<void> => {
    await fetch('/signout');
    clearUserState();
    posthog.reset();
    window.location.reload();
};
